import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/restartirat-zrenieto-header.jpg"
import LuteinComplexTablet from "./../../images/restartirat-zrenieto-header.jpg"
import LuteinComplexDesktop from "./../../images/restartirat-zrenieto-header.jpg"
import LuteinComplexXL from "./../../images/restartirat-zrenieto-header.jpg"

import lutShield from "./../../images/restartirat-eye.jpg"
import warningSign from "./../../images/lut-virusi-warning-sign.svg"

import lutein from "./../../images/lut-virusi-lutein-complex.jpg"


const LuteinRestart = () => (
  <Layout backButton={true} pageInfo={{ pageName: "lutein-restart" }}>
    <Seo title="Добра новина: Рестартират зрението" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              ДОБРА НОВИНА: РЕСТАРТИРАТ ЗРЕНИЕТО
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Добра новина: Рестартират зрението"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="mb-4">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Добра новина:
            <br /> Рестартират зрението
          </h1>
          <h2 className="font-light ">
            <em>Този път медицината успя</em>
          </h2>
        </Col>
        <Col sm={12}>
          <p>
            Своеобразен рестарт на зрението е последната добра новина, поднесена
            ни от медицинските среди. Дошла от Франция, специална разработка за
            укрепване работата на очите може да служи като своего рода
            „чистачка“ срещу замъглено зрение, твърдят запознати. Без значение
            от възрастта, тя работи безотказно при редица проблеми,
            подсигурявайки на очите всичко най-необходимо. У нас експертната
            формулировка е позната под името ЛУТЕИН КОМПЛЕКС ПЛЮС.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={8}>
          <h4>Експертно решение</h4>
          <p>
            Забележителната си мощ, която веднага го отличава от стандартните
            формули, френското творение дължи на специалната технология за
            неговото производство – LICAPS™. С нейна помощ ЛУТЕИН КОМПЛЕКС ПЛЮС
            не просто дава на тялото онова, което му е необходимо, а му го
            осигурява във вида, в който то може да се възползва по най-добрия
            начин – под формата на маслен разтвор. Течнос-тите доказано се
            усвояват по-пълноценно от организма, а това е гаранция за бързо
            постигане на желания резултат върху зрението. Още един плюс, на
            който <Link to="/lutein-complex">ЛУТЕИН КОМПЛЕКС ПЛЮС</Link> дължи
            високата си ефективност, е експертно подбраният състав, комбиниран
            така, че да покрива постоянно нарастващите с възрастта нужди на
            очите.
          </p>
        </Col>
        <Col sm={4}>
          <Image className="w-100 float-right" src={lutShield} />
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>Потвърдено от практиката</h4>

          <p>
            Спечелил вниманието както на експертите в областта на очното здраве,
            така и на потърпевшите, специалният продукт доказа своята
            ефективност и на практика. Показвайки на какво е способен в
            продължение на повече от 10 години, той се превърна в истински
            фаворит на препатилите. В негово лице те виждат утвърден и доверен
            помощник, който им връща възможността отново да се радват на
            красотата на заобикалящия ги свят.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>Орлов поглед</h4>

          <p>
            Предлагайки отлична подкрепа за очите,{" "}
            <Link to="/lutein-complex">ЛУТЕИН КОМПЛЕКС ПЛЮС</Link> помага при
            широк спектър от смущения в тяхната функция като например
            замъгляване, умора и напрежение, синдром на сухото око, дискомфорт
            вследствие на прекомерно стоене пред компютър и др. Наблюденията
            сочат, че само с 1 капсула на ден за 3 месеца с негова помощ
            зрението отново става остро и ясно независимо от възрастта. Дори се
            подобрява виждането при недостатъчна светлина и през нощта.
          </p>
          <p>
            Клинично потвърдено е също така, че с комплексното действие на
            вложените в него съставки ЛУТЕИН КОМПЛЕКС ПЛЮС съдейства за
            намаляване на риска от естествената биологична прогресия на
            възрастово свързаната макулна дегенерация с цели 25%, което от своя
            страна е предпоставка за поддържане на нормалното зрение при
            възрастни хора. В допълнение, полезният комплекс в ЛУТЕИН КОМПЛЕКС
            ПЛЮС допринася за проясняване на замъгленото зрение и функционалното
            състояние на ретината – ефект, който е особено ценен за хората в
            по-напреднала възраст.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col className="alert alert-secondary">
          <Image
            style={{
              margin: `1rem`,
              width: `80px`,
              float: `left`,
            }}
            src={warningSign}
          />
          <h3 className="warning-text">
            <strong>Катарактата води до слепота</strong>
          </h3>
          <h4>
            <em>Три малко познати факти за катарактата</em>
          </h4>

          <p className="warning-text">
            <strong>Пелената не е пред окото</strong>
            <br />
            Когато чуят „перде на очите“, както е популярното наименование на
            катаракта, болшинството от хората си представят, че пред органите на
            зрението има завеса. В действителност обаче истината е друга!
            Пердето не се образува пред, а вътре в самото око.
          </p>
          <p className="warning-text">
            <strong>Може да е засегнато само едното око</strong>
            <br />
            Противно на широкото схващане, че катаракта засяга и двете очи, не
            са редки случаите, в които помътняване на лещата се наблюдава само в
            едно око.
          </p>
          <p className="warning-text">
            <strong>Възрастта не е определяща</strong>
            <br />
            Друго, което не е толкова известно за катаракта, е, че все повече се
            подмладява. Ако преди очното заболяване е било „привилегия“ на
            възрастните, днес то се открива сред все повече млади хора.
          </p>
        </Col>
      </Row>
      <Row className="product-container">
        <Col md={{ span: 8, offset: 2 }}>
          <Link to="/lutein-complex">
            <Image className="w-100" src={lutein} />
          </Link>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>
            Можете да откриете ЛУТЕИН КОМПЛЕКС ПЛЮС в най-близката до вас
            аптека.
          </p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:0876181000">0876 18 1000</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/lutein-complex">
              Повече информация за ЛУТЕИН КОМПЛЕКС ПЛЮС можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            *Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default LuteinRestart
